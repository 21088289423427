import React from "react"
import { Layout } from "../components/Layout"
import { Styled } from "theme-ui"

const NotFoundPage = () => (
  <Layout>
    <Styled.h1>NOT FOUND</Styled.h1>
    <Styled.p>
      If you were looking for an event, it may have ended, you may be lost, or
      something may have broken. Reach out to a site administrator for help.
    </Styled.p>
  </Layout>
)

export default NotFoundPage
